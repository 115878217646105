export const STORE_PROJECT_ID = "STORE_PROJECT_ID";
export const STORE_USER_FIRST_ACCESS = "STORE_USER_FIRST_ACCESS"
export const STORE_USERS = "STORE_USERS";
export const STORE_PROJECTS = "STORE_PROJECTS";
export const STORE_OWNER_ID = "STORE_OWNER_ID";
export const STORE_USER_TO_USERS = "STORE_USER_TO_USERS";
export const STORE_CEP = "STORE_CEP";
export const STORE_PROJECT_TO_PROJECTS = "STORE_PROJECT_TO_PROJECTS";
export const STORE_ENGINEERING_TABLE = "STORE ENGINEERING_TABLE";
export const STORE_COMMERCIAL_TABLE = "STORE_COMMERCIAL_TABLE";
export const RESET_PROJECTS = "RESET_PROJECS";
export const ERASE_PROJECTS = "ERASE_PROJECTS";
export const ERASE_ALL = "ERASE_ALL";