//#7eff00
//#054d00

//const awsUrl = 'https://api.vertecotech.com';
//const stage = 'http://18.216.226.85:8000';
//const localHost = 'http://localhost:8000';


//quality
//const quality = 'http://54.94.121.89:8000';
//export const currentUrl = quality;

//producao
const prod = 'https://vertcarboncredit.com/api/'
export const currentUrl = prod;

//localhost
//const localHost = 'http://localhost:8000';
//export const currentUrl = localHost;


// atenção:

// sempre que for usar a sidebar, tratar da seguinte forma:

// <Container collapsed={collapsed}>
//  <Sidebar />
//  <InnerContainer collapsed ? 90vw : 85vw>
//   <Content />
//  </InnerContainer>
// </Container>

// 